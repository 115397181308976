import React from 'react'

const styles = {
  color: '#444'
}

const userDeleteModal = (props) => {
  return (
    <div style={styles}>
      Confirm to delete firmware version <strong>{props.name}</strong> from system?
    </div>
  )
}

export default userDeleteModal