import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types'

import axios from './service/axios'
import PrivateRoute from './helpers/PrivateRoute/PrivateRoute'
import AuthContext from './context/authContext'

// pages
import Users from './containers/Users/Users'
import Firmware from './containers/Firmware/Firmware'
import Login from './containers/Login/Login'
import NotFound from './containers/NotFound/NotFound'
import ForgetPassword from './components/ForgetPassword/ForgetPassword'
import Success from './containers/Success/Success'
import ErrorPage from './containers/Error/Error'
import Verify from './containers/Verify/Verify'

class Routes extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      isAuth: true,
      drawerOpen: false
    }
    let vm = this;
    axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      if (error.response.status === 401) {
        vm.setState({ isAuth: false })
      } else if (error.response.status === 403) {
        vm.setState({ isAuth: false })
      } else {
        return Promise.reject(error)
      }
    })
  }

  authenticated = () => {
    this.setState({ isAuth: true })
  } 

  authLogout = () => {
    this.setState({ isAuth: false })
  }

  drawerToggle = () => {
    this.setState(prevState => ({
      drawerOpen: !prevState.drawerOpen
    }))
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          isAuth: this.state.isAuth,
          drawerOpen: this.state.drawerOpen,
          drawerToggle: this.drawerToggle,
          authenticated: this.authenticated,
          authLogout: this.authLogout
        }}
      >
        <Switch>
          <Route
            component={Verify}
            exact
            path="/verified"
          />
          <Route
            component={Success}
            exact
            path="/success"
          />
          <Route
            component={ErrorPage}
            exact
            path="/invalid"
          />
          <Route
            component={ForgetPassword}
            exact
            path="/forgetpassword/:id/:token"
          />
          <Route
            component={Login}
            exact
            path="/login"
          />
          <PrivateRoute
            isAuth={this.state.isAuth}
            component={Users}
            exact
            path="/users"
          />
          <PrivateRoute
            isAuth={this.state.isAuth}
            component={Firmware}
            exact
            path="/firmwares"
          />
          <PrivateRoute
            isAuth={this.state.isAuth}
            component={NotFound}
            exact
            path="/not-found"
          />
          {
            this.state.isAuth
            ? <Redirect
              exact
              from="/"
              to="/users"
            />
            : null
          }
          <Redirect to="/invalid" />
        </Switch>
      </AuthContext.Provider>
    );
  }
}

export default withRouter(Routes)
