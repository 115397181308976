import React from 'react'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';

import Button from '@material-ui/core/Button';

import styles from './UsersResetModal.css'

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const usersResetModal = (props) => {
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.handleClose}
      classes={{ paper: styles.largeModal }}
    >
      <div className={styles.ModalTitle }>
        {props.title || 'Modal Title' }
      </div>
      <DialogContent>
        <div>Confirm to reset user?</div>
      </DialogContent>
      <DialogActions>
        <Button
          type='button'
          onClick={props.handleClose}
          style={{ color: 'rgb(225, 0, 80)' }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={props.handleOK}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default usersResetModal
