import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withRouter } from "react-router";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import styles from './ForgetPassword.css'
import CompanyLogo from '../../assets/images/companyLogo.svg'
import Footer from '../../components/Footer/Footer'
import FormInput from '../../components/Form/FormInput'
import axios from '../../service/axios';


const resetPasswordScheme = Yup.object().shape({
  password: Yup.string()
    .min(8, 'At least 8 characters.')
    .required('Required'),
  passwordConfirm: Yup.string()
    .min(8, 'At least 8 characters.')
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
});

class ForgetPassword extends Component {
  state = {
    id: '',
    token: ''
  }

  componentWillMount () {
    if (this.props.match.params.id && this.props.match.params.token) {
      this.setState({
        id: this.props.match.params.id,
        token: this.props.match.params.token
      })
    } else {
      this.props.history.push('/error')
    }
  }

  submitLoginForm (values) {
    let data = {
      token: this.state.token,
      password: values.password,
      id: this.state.id,
    }
    axios.post('/user/resetPassword', data)
    .then(() => {
      this.props.history.push('/success')
    })
    .catch(() => {
      this.props.history.push('/error')
    })
  }

  render () {
    return (
      <div className={styles.ForgetPassword}>
        <Paper
          className={styles.paper}
        >
          <div className={styles.LogoContainer}>
            <img src={CompanyLogo} alt='companyLogo' className={styles.CompanyLogo}/>
          </div>
          <div className={styles.titleContainer}>
            <Formik
              initialValues={{
                password: '',
                passwordConfirm: '',
              }}
              validationSchema={resetPasswordScheme}
              onSubmit={values => {
                this.submitLoginForm(values)
              }}
              render={form => (
                <Form>
                  <FormInput
                    name='password'
                    label='Password'
                    type='password'
                    form={form}
                  />
                  <FormInput
                    name='passwordConfirm'
                    label='Password Confirm'
                    type='password'
                    form={form}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className={styles.LoginButton}
                    type='submit'
                  >
                    Reset
                  </Button>
                </Form>
              )}
            />
          </div>
        </Paper>
        <div className={styles.Footer}>
          <Footer />
        </div>
      </div>
    )
  }
}

ForgetPassword.propTypes = {
}

export default withRouter(ForgetPassword)
