import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';

import styles from './Modal.css'

function Transition(props) {
  return <Slide direction="up" {...props} />;
}


const modal = (props) => {

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.handleClose}
      disableBackdropClick={props.modalStatus === 'loading'}
      fullWidth
    >
      <div className={styles.ModalTitle }>
        {props.title || 'Modal Title' }
      </div>
      <DialogContent>
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} style={{ color: 'rgb(225, 0, 80)' }} disabled={props.disabled}>
          Cancel
        </Button>
        <Button
          onClick={props.handleOk}
          color="primary"
          disabled={props.disabled}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default modal
