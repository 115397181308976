import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'formik';
import TextField from '@material-ui/core/TextField';

import styles from './FormInput.css'


const formInput = (props) => {
  return (
    <div className={styles.FormInput}>
      <TextField
        label={props.label}
        className={props.className || styles.TextField}
        value={props.form.values[props.name]}
        onChange={props.form.handleChange}
        onBlur={props.form.handleBlur}
        margin="normal"
        variant="outlined"
        type={props.type}
        multiline={props.multiline}
        inputProps={{
          name: props.name,
          className: styles.InputField
        }}
        disabled={props.disabled || !props.disabled === ''}
      />
      {
        props.remarks
        ? <p className={styles.remarkText}>{props.remarks}</p>
        : null
      }
      <ErrorMessage name={props.name}>
        {msg => <div className={styles.ErrorText}>{msg}</div>}
      </ErrorMessage>
    </div>
  )
}

formInput.prototype = {
  name: PropTypes.string.isRequired,
}

formInput.defaultProps = {
  label: '',
  className: '',
  name: '',
  type: 'text'
}

export default formInput