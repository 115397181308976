import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withRouter } from "react-router";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import styles from './Login.css'
import CompanyLogo from '../../assets/images/companyLogo.svg'
import Footer from '../../components/Footer/Footer'
import FormInput from '../../components/Form/FormInput'
import axios from '../../service/axios'
import AuthContext from '../../context/authContext'

const adminLoginSchema = Yup.object().shape({
  loginname: Yup.string()
    .required('Required'),
    password: Yup.string()
    .required('Required'),
});

class Login extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loginError: ''
    }
  }

  submitLoginForm = (values, context) => {
    axios.post('/admin/login', values)
    .then((res) => {
      if (res) {
        context.authenticated()
        this.props.history.push('/')
      } else {
        this.setState({ loginError: 'The user account name or password is incorrect.' })
      }
    })
    .catch(() => {
      this.setState({ loginError: 'The user account name or password is incorrect.' })
    })
  }

  render () {
    return (
      <div className={styles.Login}>
        <Paper className={styles.paper}>
          <div className={styles.LogoContainer}>
            <img src={CompanyLogo} alt='companyLogo' className={styles.CompanyLogo}/>
          </div>
          <div className={styles.titleContainer}>
            <AuthContext.Consumer>
              {
                context => (
                  <Formik
                    initialValues={{
                      loginname: '',
                      password: '',
                    }}
                    validationSchema={adminLoginSchema}
                    onSubmit={values => {
                      this.submitLoginForm(values, context)
                    }}
                    render={form => (
                      <Form>
                        <FormInput
                          name='loginname'
                          label='Login Name'
                          form={form}
                        />
                        <FormInput
                          name='password'
                          label='Password'
                          type='password'
                          form={form}
                        />
                        {
                          this.state.loginError
                          ? <div className={styles.ErrorText}>{this.state.loginError}</div>
                          : null
                        }
                        <Button
                          variant="contained"
                          color="primary"
                          className={styles.LoginButton}
                          type='submit'
                        >
                          Login
                        </Button>
                      </Form>
                    )}
                  />
                )
              }
            </AuthContext.Consumer>
          </div>
        </Paper>
        <div className={styles.Footer}>
          <Footer />
        </div>
      </div>
    )
  }
}

export default withRouter(Login)
