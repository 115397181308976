import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Lottie from 'react-lottie'

import styles from './Verify.css'
import Footer from '../../components/Footer/Footer'
import VerifyLottie from '../../assets/lottie/verify.json'

class Verify extends Component {
  render () {

    const defaultOptions = {
      loop: false,
      autoplay: true, 
      animationData: VerifyLottie,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }

    return (
      <div className={styles.Verify}>
        <div className={styles.wrapper}>
          <Paper classes={{ root: styles.searchPaper}}>
            <Lottie
              options={defaultOptions}
              height={400}
              width={400}
            />
            <div className={styles.textColumn}>
              <p className={styles.headerText}>Account</p>
              <p className={styles.subHeaderText}>Verified</p>
            </div>
            
          </Paper>
        </div>
        <Footer />
      </div>
    )
  }
}

export default Verify
