import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormInput from '../../../../components/Form/FormInput'
import styles from './EditFirmwareModal.css'

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const editSchema = Yup.object().shape({
  version: Yup.string()
    .required('Required'),
  description: Yup.string()
    .max(200, 'Max 200 characters')
    .required('Required'),
  description_zh: Yup.string()
    .max(200, 'Max 200 characters')
    .required('Required'),
  description_sc: Yup.string()
    .max(200, 'Max 200 characters')
});

const editFirmwareModal = (props) => {
  const DialogClass = () => {
    if (props.submitStatus === 'loading') {
      return styles.smallModal
    } else if (props.submitStatus.staffid) {
      return styles.mediumModal
    } else if (
      props.submitStatus === '' ||
      props.submitStatus === 'error' ||
      props.submitStatus === 'file'
    ) {
      return styles.largeModal
    }
  }

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.handleClose}
      disableBackdropClick={props.submitStatus === 'loading'}
      classes={{ paper: DialogClass() }}
    >
      <div
        className={
          props.submitStatus === '' ||
          props.submitStatus === 'error' ||
          props.submitStatus === 'file'
          ? styles.show
          : styles.hide
        }
      >
        <Formik
          initialValues={props.firmware}
          validationSchema={editSchema}
          onSubmit={(values, actions) => {
            props.submittinForm(values, (error) => {
              actions.setErrors(error)
            })
          }}
          render={
            form => (
              <Form>
                <div className={styles.ModalTitle }>
                  {props.title || 'Modal Title' }
                </div>
                <DialogContent>
                  {
                    props.submitStatus === 'error'
                    ? <p className={styles.errorMsg}>Error occurs, please try again.</p>
                    : null
                  }
                  <FormInput
                    name='version'
                    label='Version'
                    form={form}
                  />
                  <FormInput
                    name='description'
                    label='Description'
                    form={form}
                    multiline
                  />
                  <FormInput
                    name='description_zh'
                    label='Description Traditional Chinese'
                    form={form}
                    multiline
                  />
                  <FormInput
                    name='description_sc'
                    label='Description Simplified Chinese'
                    form={form}
                    multiline
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    type='button'
                    onClick={props.handleClose}
                    style={{ color: 'rgb(225, 0, 80)' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                  >
                    OK
                  </Button>
                </DialogActions>
              </Form>
            )
          }
        />
      </div>
      <div
        className={
          props.submitStatus === 'loading'
          ? [styles.show, styles.alignCenter].join(' ')
          : styles.hide
        }
      >
        <DialogContent
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        >
          <CircularProgress
            color="primary"
          />
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default editFirmwareModal