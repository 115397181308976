import React, { Component } from 'react'

import Layout from '../../components/Layout/Layout'

class NotFound extends Component {
  render () {
    return (
      <Layout title='Page Not Found'>
        <div>NotFound</div>
      </Layout>
    )
  }
}

export default NotFound
