import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SignoutIcon from '@material-ui/icons/ExitToAppOutlined';
import { withRouter } from "react-router";
import ReactTooltip from 'react-tooltip'

import styles from './Layout.css'
import NavigationItems from '../NavigationItems/NavigationItems'
import axios from '../../service/axios'
import AuthContext from '../../context/authContext'
import SnackBar from '../../components/SnackBar/SnackBar'
import Drawer from '@material-ui/core/Drawer';

class Layout extends Component {
  state = {
    drawerOpen: false,
    snackBarVisible: false,
    snackBarMessage: '',
    resetAdminPWModalVisible: false,
    resetAdminStatus: ''
  }

  drawerOpenHandler = () => {
    this.setState(prevState => ({
      drawerOpen: !prevState.drawerOpen
    }))
  }

  snackBarCloseHandler = () => {
    this.setState({ snackBarVisible: false, snackBarMessage: '' })
  }

  LogoutOnClick = (context) => {
    axios.post('admin/logout')
    .then(() => {
      context.authLogout()
      localStorage.removeItem('adminid')
    })
    .catch(() => {
      this.returnInternetError()
    })
  }

  returnInternetError = () => {
    this.setState({
      snackBarVisible: true,
      snackBarMessage: `Please check your internet connection!`
    })
  }

  resetAdminPassword = () => {
    this.setState({ resetAdminPWModalVisible: true })
  }

  resetAdminPWCloseHandler = () => {
    this.setState({ resetAdminPWModalVisible: false, resetAdminStatus: '' })
  }

  copyOnClick = () => {
    this.setState({
      snackBarVisible: true,
      snackBarMessage: 'Copied!'
    })
  }

  submittinForm = (values, actions) => {
    this.setState({ resetAdminStatus: 'loading' })
    const data = {
      oldPassword: values.password,
      password: values.newPassword
    }
    axios.post('/admin/password', data)
    .then((res) => {
      this.setState({
        resetAdminPWModalVisible: false,
        resetAdminStatus: '',
        snackBarVisible: true,
        snackBarMessage: `Reset admin password successfully!`
      })
    })
    .catch((error) => {
      if (error.response.status === 404) {
        actions({ password: 'Incorrect passwrod' })
        this.setState({ resetAdminStatus: '' })
      } else if (error.response.status === 400) {
        this.setState({ resetAdminStatus: 'error' })
      } else {
        this.setState({ resetAdminStatus: '' })
        this.returnInternetError()
      }
    })
  }

  returnInternetError = () => {
    this.setState({
      snackBarVisible: true,
      snackBarMessage: `Please check your internet connection!`
    })
  }

  render () {
    return (
      <div className={styles.PanelLayout}>
        <AuthContext.Consumer>
          {
            context => (
              <Fragment>
                {/* web drawer */}
                <div className={context.drawerOpen ? styles.Drawer : styles.Hide}>
                  <NavigationItems
                    open={context.drawerOpen}
                  />
                </div>
                {/* mobile drawer */}
                <Drawer
                  open={context.drawerOpen}
                  onClose={context.drawerToggle}
                  classes={{ root: styles.mobileDrawer }}
                >
                  <NavigationItems
                    open={context.drawerOpen}
                  />
                </Drawer>
                <div className={styles.Content}>
                  <div style={{ width: '100%' }}>
                    <AppBar position="sticky" classes={{ root: styles.AppBarRoot }}>
                      <Toolbar>
                        <IconButton
                          className={styles.MenuButton}
                          color="inherit"
                          aria-label="Menu"
                          onClick={context.drawerToggle}
                        >
                          <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={styles.grow}>
                          {this.props.title}
                        </Typography>
                        <IconButton
                          className={styles.MenuButton}
                          data-tip='Logout'
                          onClick={() => this.LogoutOnClick(context)}
                        >
                          <SignoutIcon />
                        </IconButton>
                      </Toolbar>
                    </AppBar>
                    {this.props.children}
                  </div>
                </div>
              </Fragment>
            )
          }
        </AuthContext.Consumer>
        <SnackBar
          open={this.state.snackBarVisible}
          vertical='top'
          horizontal='right'
          handleClose={this.snackBarCloseHandler}
          message={this.state.snackBarMessage}
        />
        <ReactTooltip className={styles.ToolTip} place='bottom' />
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
}

export default withRouter(Layout)
