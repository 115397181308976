import React from 'react';
import { Route, Redirect } from 'react-router-dom'

const privateRoute = ({ component: Component, isAuth, ...rest}) => {
  return (
    <Route
      {...rest}
      render={ props =>
        isAuth
        ? (
          <Component {...props} />
        )
        : (
          <Redirect to='/login' />
        )
      }
    />
  )
}

export default privateRoute