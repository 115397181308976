import React from 'react';

const authContext = React.createContext({
  isAuth: false,
  drawerOpen: false,
  authenticated: () => {},
  authLogout: () => {}
});

export default authContext;
