import React, { Component, Fragment } from 'react'
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/PersonAdd';

import Layout from '../../components/Layout/Layout'
import styles from './Firmware.css'
import SnackBar from '../../components/SnackBar/SnackBar'
import Footer from '../../components/Footer/Footer'
import axios from '../../service/axios';
import FirmwareTable from './components/FirmwareTable/FirmwareTable'
import UploadFirmwareModal from './components/UploadFirmwareModal/UploadFirmwareModal'
import Modal from '../../components/Modal/Modal'
import DeleteFirmwareModal from './components/DeleteFirmwareModal/DeleteFirmwareModal'
import ToggleFirmwareModal from './components/ToggleFirmwareModal/ToggleFirmwareModal'
import EditFirmwareModal from './components/EditFirmwareModal/EditFirmwareModal'

class Firmware extends Component {

  constructor (props) {
    super(props)
    this.state = {
      filterObject: { limit: 20, offset: 0, showUnPublish: true },
      firmwareList: [],
      firmwareCount: 0,
      page: 0,
      snackBarVisible: false,
      snackBarMessage: '',
      addNewFirmwareVisible: false,
      uploadStatus: '',
      file: null,
      deleteFirmwareModalVisible: false,
      deleteFirmwareStatus: '',
      selectedItem: null,
      firmwarePublishToggleModalVisible: false,
      toggleStatus: '',
      editFirmwareModalVisible: false,
      editStatus: '',

    }
  }

  componentDidMount () {
    this.getFirmwareList()
  }

  getFirmwareList = () => {
    axios.post('/firmware/list', this.state.filterObject)
    .then((res) => {
      this.setState({
        firmwareList: res.data.rows,
        firmwareCount: res.data.count
      })
    })
    .catch(() => {
      this.returnInternetError()
    })
  }

  handleChangePage = (event, page) => {
    const newFilter = { ...this.state.filterObject }
    newFilter['offset'] = page * newFilter.limit
    this.setState({ page, filterObject: newFilter},
      () => this.getFirmwareList()
    )
  }

  handleChangeRowsPerPage = event => {
    const newFilter = { ...this.state.filterObject }
    newFilter['limit'] = event.target.value
    newFilter['offset'] = 0
    this.setState({ filterObject: newFilter, page: 0 },
      () =>  this.getFirmwareList()
    )
  }

  returnInternetError = () => {
    this.setState({
      snackBarVisible: true,
      snackBarMessage: `Please check your internet connection!`
    })
  }
  
  addNewOnPress = () => {
    this.setState({ addNewFirmwareVisible: true })
  }

  handleUploadModalClose = () => {
    this.setState({ addNewFirmwareVisible: false, uploadStatus: '' })
  }

  uploadModalSubmittingFormHandler = (values) => {
    this.setState({ uploadStatus: 'loading' })
    let formData = new FormData()
    if (this.state.file) {
      formData.append('firmware', this.state.file)
      formData.append('version', values.version)
      formData.append('description', values.description)
      formData.append('description_zh', values.description_zh)
      if (values.description_sc) {
        formData.append('description_sc', values.description_sc)
      }
      axios.post('firmware', formData)
      .then((res) => {
        this.setState({
          addNewFirmwareVisible: false,
          uploadStatus: '',
          file: null,
          snackBarVisible: true,
          snackBarMessage: 'Upload firmware successfully!'
        },
          () => this.getFirmwareList()
        )
      })
      .catch(() => {
        this.setState({ uploadStatus: 'error' })
      })
    } else {
      this.setState({ uploadStatus: 'file' })
    }
  }

  firmwareOnDrop = (file) => {
    this.setState({ file: file[0] })
  }

  deleteFile = () => {
    this.setState({ file: null })
  }

  deleteOnClick = (item) => {
    this.setState({ deleteFirmwareModalVisible: SVGComponentTransferFunctionElement, selectedItem: item })
  }

  handleDeleteModalClose = () => {
    this.setState({ deleteFirmwareModalVisible: false, deleteFirmwareStatus: '', selectedItem: null })
  }

  deleteFirmwareHandler = () => {
    this.setState({ deleteFirmwareStatus: 'loading' })
    let firmware = {...this.state.selectedItem}
    axios.delete(`firmware/${firmware.firmwareid}`)
    .then(() => {
      this.setState({
        deleteFirmwareModalVisible: false,
        deleteFirmwareStatus: '',
        selectedItem: null,
        snackBarVisible: true,
        snackBarMessage: 'Delete firmware successfully!'
      },
        () => this.getFirmwareList()
      )
    })
    .catch(() => {
      this.setState({ deleteFirmwareStatus: 'error' })
      this.returnInternetError()
    })
  }

  snackBarCloseHandler = () => {
    this.setState({ snackBarVisible: false, snackBarMessage: '' })
  }

  firmwarePublishToggle = (item) => {
    this.setState({ firmwarePublishToggleModalVisible: true, selectedItem: item })
  }

  handleToggleModalClose = () => {
    this.setState({ firmwarePublishToggleModalVisible: false, selectedItem: null, toggleStatus: '' })
  }

  toggleFirmwareHandler = () => {
    this.setState({ toggleStatus: 'loading' })
    let firmware = {...this.state.selectedItem}
    if (firmware.isPublish) {
      axios.post(`/firmware/${firmware.firmwareid}/unpublish`)
      .then(() => {
        this.setState({
          firmwarePublishToggleModalVisible: false,
          selectedItem: null,
          toggleStatus: '',
          snackBarVisible: true,
          snackBarMessage: 'Unpublish firmware successfully!'
        },
          () => this.getFirmwareList()
        )
      })
      .catch(() => {
        this.setState({ toggleStatus: 'error' })
      })
    } else {
      axios.post(`/firmware/${firmware.firmwareid}/publish`)
      .then(() => {
        this.setState({
          firmwarePublishToggleModalVisible: false,
          selectedItem: null,
          toggleStatus: '',
          snackBarVisible: true,
          snackBarMessage: 'Publish firmware successfully!'
        },
          () => this.getFirmwareList()
        )
      })
      .catch(() => {
        this.setState({ toggleStatus: 'error' })
      })
    }
  }

  editFirmwareOnClick = (item) => {
    this.setState({ editFirmwareModalVisible: true, selectedItem: item  })
  }

  handleEditFirmwareModalClose = () => {
    this.setState({ editFirmwareModalVisible: false, selectedItem: null, editStatus: '' })
  }

  editModalSubmittingFormHandler = (values) => {
    let firmware = {...this.state.selectedItem}
    this.setState({ editStatus: 'loading' })
    axios.patch(`/firmware/${firmware.firmwareid}`, values)
    .then(() => {
      this.setState({
        editFirmwareModalVisible: false,
        selectedItem: null,
        editStatus: '',
        snackBarVisible: true,
        snackBarMessage: 'Edit firmware successfully!'
      },
        () => this.getFirmwareList()
      )
    })
    .catch(() => {
      this.setState({ editStatus: 'error' })
    })
  }

  render () {
    return (
      <Fragment>
        <Layout title='Firmwares'>
          <div className={styles.Firmware}>
            <div className={styles.ToolBar}>
              <div className={styles.buttonRow}>
                <Button color='primary' variant="contained" onClick={this.addNewOnPress}>
                  Add <AddIcon style={{ marginLeft: 5 }} />
                </Button>
              </div>
            </div>
            <FirmwareTable
              firmwareCount={this.state.firmwareCount}
              firmwareList={this.state.firmwareList}
              handleChangePage={this.handleChangePage}
              page={this.state.page}
              rowsPerPage={this.state.filterObject.limit}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              deleteOnClick={this.deleteOnClick}
              firmwarePublishToggle={this.firmwarePublishToggle}
              editFirmwareOnClick={this.editFirmwareOnClick}
            />
            <div className={styles.footer}>
              <Footer />
            </div>
          </div>
        </Layout>
        {
          this.state.addNewFirmwareVisible
          ? <UploadFirmwareModal
            open={this.state.addNewFirmwareVisible}
            handleClose={this.handleUploadModalClose}
            title='Upload Firmware'
            submittinForm={this.uploadModalSubmittingFormHandler}
            submitStatus={this.state.uploadStatus}
            onDrop={this.firmwareOnDrop}
            file={this.state.file}
            deleteFile={this.deleteFile}
          />
          : null
        }
        {
          this.state.deleteFirmwareModalVisible
          ? <Modal
            handleClose={this.handleDeleteModalClose}
            open={this.state.deleteFirmwareModalVisible}
            title='Delete Firmware'
            handleOk={this.deleteFirmwareHandler}
            disabled={this.state.deleteFirmwareStatus === 'loading'}
            modalStatus={this.state.deleteFirmwareStatus}
          >
            <DeleteFirmwareModal
              name={this.state.selectedItem ? this.state.selectedItem.version : ' - '}
              modalStatus={this.state.deleteFirmwareStatus}
            />
          </Modal>
          : null
        }
        {
          this.state.firmwarePublishToggleModalVisible
          ? <Modal
            handleClose={this.handleToggleModalClose}
            open={this.state.firmwarePublishToggleModalVisible}
            title={this.state.selectedItem.isPublish ? 'Unpublish Firmware' : 'Publish Firmware'}
            handleOk={this.toggleFirmwareHandler}
            disabled={this.state.toggleStatus === 'loading'}
            modalStatus={this.state.toggleStatus}
          >
            <ToggleFirmwareModal
              firmware={this.state.selectedItem}
              modalStatus={this.state.toggleStatus}
            />
          </Modal>
          : null
        }
        {
          this.state.editFirmwareModalVisible
          ? <EditFirmwareModal
            open={this.state.editFirmwareModalVisible}
            handleClose={this.handleEditFirmwareModalClose}
            title='Edit Firmware'
            submittinForm={this.editModalSubmittingFormHandler}
            submitStatus={this.state.editStatus}
            firmware={this.state.selectedItem}
          />
          : null
        }
        <SnackBar
          open={this.state.snackBarVisible}
          vertical='top'
          horizontal='right'
          handleClose={this.snackBarCloseHandler}
          message={this.state.snackBarMessage}
        />
      </Fragment>
    )
  }
}

export default Firmware
