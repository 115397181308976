import React from 'react'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table';
import Chip from '@material-ui/core/Chip';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import Button from '@material-ui/core/Button';
import {
  FiDownloadCloud,
  FiUploadCloud
} from 'react-icons/fi'

import styles from './FirmwareTable.css'

const firmwareTable = (props) => {
  const renderStatus = (status) => {
    if (status) {
      return (
        <Chip
          label='Published'
          variant='outlined'
          classes={{ root: styles.chipPublish }}
        />
      )
    } else {
      return (
        <Chip
          label='Unpublished'
          variant='outlined'
          classes={{ root: styles.chipUnpublish }}
        />
      )
    }
  }
  return (
    <Paper className={styles.FirmwareTable}>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={props.firmwareCount}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={props.handleChangePage}
        onChangeRowsPerPage={props.handleChangeRowsPerPage}
      />
      <div className={styles.TableWrapper}>
        <Table className={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell classes={{ root: styles.TableHead}}>Firmware Version</TableCell>
              <TableCell classes={{ root: styles.TableHead}}>Description</TableCell>
              <TableCell align="right" classes={{ root: styles.TableHead}}>Create Time</TableCell>
              <TableCell align="right" classes={{ root: styles.TableHead}}>Status</TableCell>
              <TableCell align="right" classes={{ root: styles.TableHead}}>Operations</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.firmwareList.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.version}
                </TableCell>
                <TableCell>
                  {row.description}
                </TableCell>
                <TableCell align="right">
                  {moment(row.createdAt).format('lll')}
                </TableCell>
                <TableCell align="right">
                  {renderStatus(row.isPublish)}
                </TableCell>
                <TableCell align="right" style={{ padding: '4px 24px' }}>
                  <Button
                    color="primary"
                    onClick={() => props.editFirmwareOnClick(row)}
                    data-tip='Edit Name'
                  >
                    <EditIcon />
                  </Button>
                  <Button
                    onClick={() => props.firmwarePublishToggle(row)}
                    style={row.isPublish ? { color: '#E10050' } : { color: '#1abc9c'} }
                    data-tip={row.isPublish ? 'Unpublish' : 'Publish' }
                  >
                    {
                      row.isPublish
                      ? <FiDownloadCloud size={20} />
                      : <FiUploadCloud size={20} />
                    }
                  </Button>
                  <Button
                    onClick={() => props.deleteOnClick(row)}
                    data-tip='Delete Firmware'
                  >
                    <DeleteIcon />
                  </Button>
                  <ReactTooltip className={styles.ToolTip} />
              </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={props.firmwareCount}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={props.handleChangePage}
        onChangeRowsPerPage={props.handleChangeRowsPerPage}
      />
    </Paper>
  )
}

firmwareTable.propTypes = {
  firmwareCount: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  firmwareList: PropTypes.array.isRequired,
}

export default firmwareTable