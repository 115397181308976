import React from 'react'

const styles = {
  color: '#444'
}

const toggleFirmwareModal = (props) => {
  return (
    <div style={styles}>
      Confirm to <strong>{props.firmware.isPublish ? 'unpublish ' : 'publish '}</strong>
      firmware version <strong>{props.firmware.version}</strong>?
    </div>
  )
}

export default toggleFirmwareModal