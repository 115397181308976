import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Lottie from 'react-lottie'

import styles from './Error.css'
import Footer from '../../components/Footer/Footer'
import InvalidLottie from '../../assets/lottie/invalid.json'

class Error extends Component {
  render () {

    const defaultOptions = {
      loop: false,
      autoplay: true, 
      animationData: InvalidLottie,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }

    return (
      <div className={styles.Error}>
        <div className={styles.wrapper}>
          <Paper classes={{ root: styles.searchPaper}}>
            <Lottie
              options={defaultOptions}
              height={400}
              width={400}
            />
            <div className={styles.textColumn}>
              <p className={styles.headerText}>Please try again</p>
              <p className={styles.subHeaderText}>Invalid</p>
            </div>
            
          </Paper>
        </div>
        <Footer />
      </div>
    )
  }
}

export default Error
