import React from 'react'

import styles from './Footer.css'

const getYear = () => {
  return new Date().getFullYear()
}

const footer = (props) => (
  <div className={styles.Footer}>
    <p
      className={styles.FooterLinkText}
    >
      AppicIDEA IT Solutions Ltd. {getYear()}
    </p>
  </div>
)

export default footer
