import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const snackbar = (props) => (
  <div>
    <Snackbar
      anchorOrigin={{
        vertical: props.vertical,
        horizontal: props.horizontal
      }}
      open={props.open}
      autoHideDuration={10000}
      onClose={props.handleClose}
      ContentProps={{
        "aria-describedby": "message-id"
      }}
      message={<span id="message-id">{props.message}</span>}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={props.handleClose}
        >
          <CloseIcon />
        </IconButton>
      ]}
    />
  </div>
)

export default snackbar
