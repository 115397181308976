import React from 'react'
import PropTypes from 'prop-types'
import ListItemIcon from '@material-ui/core/ListItemIcon';

import '../../../assets/css/global.css'
import styles from './NavigationItem.css'

const navigationItem = (props) => (
  <div id='ListItem' className={styles.ListItem}>
    <ListItemIcon>
      {props.icon}
    </ListItemIcon>
    <span
      id='ListItemLabel'
      className={[
        styles.labelText,
        props.open ? styles.visible : styles.hidden 
      ].join(' ')}
    >
      {props.label}
    </span>
  </div>
)

navigationItem.prototype = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired
}

export default navigationItem