import React from 'react'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table';
import Avatar from '@material-ui/core/Avatar';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import CheckIcon from '@material-ui/icons/CheckCircleOutline'
import moment from 'moment'
import {
  FaFacebookSquare,
  FaApple,
  FaGoogle,
  FaLock
} from 'react-icons/fa'

import {
  MdReplay
} from 'react-icons/md'

import styles from './UsersTable.css'

const AVATAR = require('../../../../assets/images/avatar.png')

const renderMethod = (provider) => {
  if (provider === 'facebook') {
    return (
      <FaFacebookSquare color={'#3b5998'} size={20} />
    )
  } else if (provider === 'apple') {
    return (
      <FaApple color={'#7e7e7e'} size={20} />
    )
  } else if (provider === 'google') {
    return (
      <FaGoogle color={'#de5246'} size={20} />
    )
  } else if (provider === 'password') {
    return (
      <FaLock color={'#ddd'} size={20} />
    )
  } else {
    return provider
  }
}

const userTable = (props) => (
  <Paper className={styles.UserTable}>
    <TablePagination
      rowsPerPageOptions={[5, 10, 20]}
      component="div"
      count={props.userCount}
      rowsPerPage={props.rowsPerPage}
      page={props.page}
      backIconButtonProps={{
        'aria-label': 'Previous Page',
      }}
      nextIconButtonProps={{
        'aria-label': 'Next Page',
      }}
      onChangePage={props.handleChangePage}
      onChangeRowsPerPage={props.handleChangeRowsPerPage}
    />
    <div className={styles.TableWrapper}>
      <Table className={styles.table}>
        <TableHead>
          <TableRow>
            <TableCell classes={{ root: styles.TableHead}} style={{ width: 50 }}>Avatar</TableCell>
            <TableCell classes={{ root: styles.TableHead}}>User Name</TableCell>
            <TableCell align="right" classes={{ root: styles.TableHead}}>Method</TableCell>
            <TableCell align="right" classes={{ root: styles.TableHead}}>Email</TableCell>
            <TableCell align="right" classes={{ root: styles.TableHead}}>Verified</TableCell>
            <TableCell align="right" classes={{ root: styles.TableHead}}>Create Time</TableCell>
            <TableCell align="right" classes={{ root: styles.TableHead}}>Operation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.userList.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                <Avatar src={row.avatar || AVATAR} />
              </TableCell>
              <TableCell>
                {row.fullname}
              </TableCell>
              <TableCell align="right">
                {renderMethod(row.provider)}
              </TableCell>
              <TableCell align="right">
                {row.email || '-'}
              </TableCell>
              <TableCell align="right">
                {row.isVerify ? <CheckIcon className={styles.checkIcon} /> : ' '}
              </TableCell>
              <TableCell align="right">
                {moment(row.createdAt).format('lll')}
              </TableCell>
              <TableCell align="right">
                <MdReplay
                  style={{ color: '#3498db', cursor: 'pointer' }}
                  onClick={() => props.resetOnClick(row)}
                  size={20}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
    <TablePagination
      rowsPerPageOptions={[5, 10, 20]}
      component="div"
      count={props.userCount}
      rowsPerPage={props.rowsPerPage}
      page={props.page}
      backIconButtonProps={{
        'aria-label': 'Previous Page',
      }}
      nextIconButtonProps={{
        'aria-label': 'Next Page',
      }}
      onChangePage={props.handleChangePage}
      onChangeRowsPerPage={props.handleChangeRowsPerPage}
    />
  </Paper>
)

userTable.propTypes = {
  userCount: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  userList: PropTypes.array.isRequired,
  resetOnClick: PropTypes.func.isRequired
}

export default userTable