import React from 'react'
import List from '@material-ui/core/List';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import Memory from '@material-ui/icons/Memory';

import styles from './NavigationItems.css'
import NavigationItem from './NavigationItem/NavigationItem'
import CompanyLogo from '../../assets/images/companyLogo.svg'
import MobileCompanyLogo from '../../assets/images/companyLogoSquare.png'
import { NavLink } from 'react-router-dom'

const navigationItems = (props) => (
  <div className={styles.NavigationItems}>
    <div
      className={[
        styles.LogoContainer,
        props.open ? styles.visible : styles.hidden
      ].join(' ')}
    >
      {
        props.open
        ? <img src={CompanyLogo} alt='companyLogo' className={styles.CompanyLogo} />
        : <img src={MobileCompanyLogo} alt='companyLogo' className={styles.MobileCompanyLogo} />
      }
      
    </div>
    
    <List component="nav">
      <NavLink to='/users' exact style={{ textDecoration: 'none' }}>
        <NavigationItem label='Users' icon={<SupervisedUserCircle />} open={props.open} />
      </NavLink>
      <NavLink to='/firmwares' exact style={{ textDecoration: 'none' }}>
        <NavigationItem label='Firmwares' icon={<Memory />} open={props.open} />
      </NavLink>
      
    </List>
  </div>
)

export default navigationItems