import React, { Component, Fragment } from 'react'
// import Button from '@material-ui/core/Button';
// import AddIcon from '@material-ui/icons/PersonAdd';

import Layout from '../../components/Layout/Layout'
import styles from './Users.css'
// import Modal from '../../components/Modal/Modal'
// import UserDeleteModal from './components/UserDeleteModal'
// import UserAddNewModal from './components/UserAddNewModal/UserAddNewModal'
import axios from '../../service/axios';
import UserTable from './components/UsersTable/UsersTable'
// import UserResetPasswordModal from './components/UserResetPasswordModal/UserResetPasswordModal'
// import UserEditModal from './components/UserEditModal/UserEditModal'
import SnackBar from '../../components/SnackBar/SnackBar'
import Footer from '../../components/Footer/Footer'
import UsersResetModal from './components/UsersResetModal/UsersResetModal'

class Users extends Component {

  constructor (props) {
    super(props)
    this.state = {
      filterObject: { limit: 20, offset: 0 },
      userList: [],
      userCount: 0,
      page: 0,
      deleteModalVisible: false,
      selectedItem: null,
      changePWVisible: false,
      addNewVisible: false,
      editUserVisible: false,
      addNewUserStatus: '',
      resetStatus: '',
      editStatus: '',
      deleteStatus: '',
      snackBarVisible: false,
      snackBarMessage: '',
      resetVisible: false
    }
    this.resetOnClick = this.resetOnClick.bind(this)
    this.resetOnPress = this.resetOnPress.bind(this)
  }

  componentDidMount () {
    this.getUserList()
  }

  getUserList = (cb) => {
    let filter = { ...this.state.filterObject }
    axios.post('/user/list', filter )
    .then((res) => {
      this.setState({
        userList: res.data.rows,
        userCount: res.data.count,
      },
        () => {
          if (cb) cb()
        }
      )
    })
    .catch(() => {
      this.returnInternetError()
    })
  }

  handleChangePage = (event, page) => {
    const newFilter = { ...this.state.filterObject }
    newFilter['offset'] = page * newFilter.limit
    this.setState({ page, filterObject: newFilter},
      () => this.getUserList()
    )
  }

  handleChangeRowsPerPage = event => {
    const newFilter = { ...this.state.filterObject }
    newFilter['limit'] = event.target.value
    newFilter['offset'] = 0
    this.setState({ filterObject: newFilter, page: 0 },
      () =>  this.getUserList()
    )
  }

  deleteOnClick = (row) => {
    this.setState({ deleteModalVisible: true, selectedItem: row })
  }

  handleDeleteModalClose = () => {
    this.setState({ deleteModalVisible: false, selectedItem: null })
  }

  changePWonClick = (row) => {
    this.setState({ changePWVisible: true, selectedItem: row })
  }

  handleChangeModalClose = () => {
    this.setState({ changePWVisible: false, selectedItem: null, resetStatus: '' })
  }

  addNewOnPress = () => {
    this.setState({ addNewVisible: true })
  }

  handleAddNewModalClose = () => {
    this.setState({ addNewVisible: false, addNewUserStatus: '' })
  }

  addNewUserSubmittingFormHandler = (values, errorHandler) => {
    this.setState({ addNewUserStatus: 'loading' })
    let data = {
      ...values,
      password: ''
    }
    axios.post('/staff', data)
    .then((res) => {
      data['staffid'] = res.data.staffid
      this.setState({ addNewUserStatus: data })
      this.getUserList()
    })
    .catch((err) => {
      if (err.response.status === 409) {
        this.setState({ addNewUserStatus: '' })
        errorHandler({
          loginname: 'Duplicated User ID.'
        })
      } else {
        this.setState({ addNewUserStatus: 'error' })
      }
    })
  }

  confirmReset = () => {
    this.setState({ resetStatus: 'loading' })
    const userInfo = { ...this.state.selectedItem }
    const data = {
      password: '',
      staffid: userInfo.staffid,
      loginname: userInfo.loginname
    }
    axios.post(`/staff/${this.state.selectedItem.staffid}/password`, data)
    .then((res) => {
      this.setState({ resetStatus: data })
    })
    .catch(() => {
      this.setState({ resetStatus: 'error' })
      this.returnInternetError()
    })

  }

  editUserOnClick = (row) => {
    this.setState({ editUserVisible: true, selectedItem: row })
  }

  handleEditModalClose = () => {
    this.setState({ editUserVisible: false, selectedItem: null, editStatus: '' })
  }

  editUserSubmittingFormHandler = (values) => {
    this.setState({ editStatus: 'loading' })
    let user = { ...this.state.selectedItem }
    axios.patch(`/staff/${user.staffid}`, values)
    .then(() => {
      this.getUserList(
        () => {
          this.setState({
            editUserVisible: false,
            selectedItem: null,
            editStatus: '',
            snackBarVisible: true,
            snackBarMessage: `${user.fullname} has been changed to ${values.fullname}!`
          })
        }
      )
    })
    .catch(() => {
      this.setState({ editStatus: 'error' })
      this.returnInternetError()
    })
  }

  deleteUserHandler = () => {
    this.setState({ deleteStatus: 'loading' })
    let user = {...this.state.selectedItem }
    axios.delete(`/staff/${user.staffid}`)
    .then(() => {
      this.getUserList(
        () => {
          this.setState({
            deleteModalVisible: false,
            selectedItem: null,
            deleteStatus: '',
            snackBarVisible: true,
            snackBarMessage: `${user.loginname} has been deleted successfully!`
          })
        }
      )
    })
    .catch(() => {
      this.setState({
        deleteStatus: 'error',
        snackBarVisible: true,
        snackBarMessage: `Fail to delete ${user.staffid}, please try again!`
      })
    })
  }

  snackBarCloseHandler = () => {
    this.setState({ snackBarVisible: false, snackBarMessage: '' })
  }

  copyOnClick = () => {
    this.setState({
      snackBarVisible: true,
      snackBarMessage: 'Copied!'
    })
  }

  searchOnChange = (e) => {
    e.persist()
    this.setState({ searchValue: e.target.value })
    this.searchValueOnChange(e)
  }

  setSearchValue = (e) => {
    let filter = { ...this.state.filterObject }
    filter['loginname'] = e.target.value
    this.setState({ filterObject: filter },
      () => this.getUserList()
    )
  }

  returnInternetError = () => {
    this.setState({
      snackBarVisible: true,
      snackBarMessage: `Please check your internet connection!`
    })
  }

  resetOnClick (row) {
    this.setState({ resetVisible: true, selectedItem: row })
  }

  resetOnPress () {
    const { selectedItem } = this.state
    axios.delete(`/user/${selectedItem.userid}/allcarsettings`)
    .then(() => {
      this.setState({
        snackBarVisible: true,
        snackBarMessage: `Reset user successfully.`,
        resetVisible: false,
        selectedItem: null
      })
    })
    .catch(() => {
      this.setState({
        snackBarVisible: true,
        snackBarMessage: `Fail to reset user, please try again.`,
        resetVisible: false
      })
    })
  }

  render () {
    return (
      <Fragment>
        <Layout title='Users'>
          <div className={styles.Users}>
            <div className={styles.ToolBar} />
            <UserTable
              userList={this.state.userList}
              userCount={this.state.userCount}
              handleChangePage={this.handleChangePage}
              page={this.state.page}
              rowsPerPage={this.state.filterObject.limit}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              resetOnClick={this.resetOnClick}
            />
            <div className={styles.footer}>
              <Footer />
            </div>
          </div>
        </Layout>
        {
          this.state.resetVisible
          ? <UsersResetModal
            handleClose={() => this.setState({ resetVisible: false })}
            handleOK={this.resetOnPress}
            open={this.state.resetVisible}
            title='User Reset'
          />
          : null
        }
        <SnackBar
          open={this.state.snackBarVisible}
          vertical='top'
          horizontal='right'
          handleClose={this.snackBarCloseHandler}
          message={this.state.snackBarMessage}
        />
      </Fragment>
    )
  }
}

export default Users
