import axios from 'axios'

const API_URL = 'https://remotecar.appicidea.com/'

let axiosInstance = axios.create({
  baseURL: API_URL + 'v1/',
  withCredentials: true,
})

export default axiosInstance
